@font-face {
  font-family: "MyWebFont";
  /* src: url("../../assets/fonts/SFPRODISPLAYBLACKITALIC.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYBOLD.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYHEAVYITALIC.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYLIGHTITALIC.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYMEDIUM.OTF"); */
  src: url("../../assets/fonts/SFPRODISPLAYREGULAR.OTF");
  /* src: url("../../assets/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYTHINITALIC.OTF");
  src: url("../../assets/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF"); */
}
.landing-cont {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
/* header */
.landing_header-cont {
  width: 100%;
  background: #1b1a1a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 105px;
}
.landing_header-logo {
  width: 17.8%;
  margin-top: 50px;
}
.landing_header-logo-img {
  width: 100%;
}

.landing_header-burger-btn {
  width: 2.5vw;
}
.landing_header-btnCont {
  display: flex;
  align-items: center;
}
.landing_action-btn {
  background-color: #1a73e8;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 51px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.5vw;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
}
.landing_header-block {
  width: 88%;
  margin-left: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
}
/* video cont */
.landing_video-cont {
  width: 100%;
  background-image: url("../../assets/icons/testback.webp");
  padding-bottom: 90px;
}
.landing_video-cont-header {
  padding-top: 88px;
  margin-left: 6%;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 2.9vw;
  line-height: 98.5%;
  /* or 41px */
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
}
.landing_video-cont-desc {
  margin-top: 20px;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 98.5%;
  /* or 20px */
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.55);
  margin-left: 6%;
}
.landing_video-slider-cont {
  width: 22%;
}
.landing_video-slider-text-cont {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(72.3px);
  /* Note: backdrop-filter has minimal browser support */
}
.landing_video-data-cont {
  margin-top: 83px;
  margin-left: 9%;
  display: flex;
}
.landing_video-data-block {
  width: 63%;
  height: fit-content;
  position: relative;
  margin-left: 7.43%;
  position: relative;
}
.landing_video-prew-img {
  width: 100%;
  border-radius: 20px;
}
.landing_video-rectangle {
  width: 100%;
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing_video-rectangle::before {
  content: "";
  display: block;
  padding-top: 95%; /* Равная высота блока (в примере 100%) */
}
.landing_video-rectangle-block {
  position: absolute;
  width: 8%;
  height: 80%;
  top: 10%;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing_video-rectangle-img {
  width: 40%;
}
.landing_video-slider-text-title {
  text-align: center;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 107%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 51px;
}
.landing_video-slider-text-desc {
  margin-top: 50px;
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  padding-left: 0;
}
.landing_video-slider-text-li {
  color: #fff;
  font-weight: 600;
  font-size: 1.4vw;
  margin-top: 9%;
}
.landing_video-slider-acivity-cont {
  display: flex;
  width: 96%;
  margin-left: 2%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.landing_video-slider-activity_btn {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
}
.landing_video-slider-activity_btn-cont-b {
  width: 30%;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.landing_video-slider-activity_btn-cont {
  display: flex;
  width: 193px;
  justify-content: space-between;
  align-items: center;
}
.landing_video-slider-acivity-text {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.landing_video-slider-activity_btn.active {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
}

/* slider */
.landing_slider-header {
  margin-left: 6%;
  padding-top: 83px;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 2.9vw;
  line-height: 115%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #1e1e1e;
}
.landing_slider-desc {
  margin-left: 6%;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 2.9vw;
  line-height: 115%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background: linear-gradient(180deg, #d05dde 0%, #8754d9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.landing_slider-left-text-block {
  width: 80%;
  height: fit-content;
}
.landing_slider-left-text-title {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 800;
  font-size: 1.4vw;
  letter-spacing: 0.01em;
  color: #1e1e1e;
  mix-blend-mode: normal;
  text-align: end;
}
.landing_slider-left-desc-desc {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  color: #1e1e1e;
  mix-blend-mode: normal;
  text-align: end;
  width: 95%;
  margin-left: 5%;
}
.landing_slider-left-img-block {
  width: 100.5%;
  /* height: 56px; */
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.landing_slider-left-cont {
  display: flex;
  justify-content: space-between;
  height: 30%;
  justify-content: space-between;
  margin-top: 20%;
}
.landing_slider-left-block {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing_slider-center-block {
  width: 25%;
}
.landing_slider-center-img {
  width: 100%;
  background-color: #f4f4f4;
}
.landing_slider-content-block {
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;
}
.landing_slider-right-block {
  width: 35%;

  display: flex;
  flex-direction: column;
}
.landing_slider-right-cont {
  display: flex;
  justify-content: space-between;
  height: 24.5%;
  justify-content: space-between;
  margin-top: 28%;
}
.landing_slider-right-text-block {
  width: 80%;
  height: fit-content;
}
.landing_slider-right-text-title {
  /* text-align: start; */
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 800;
  font-size: 1.4vw;

  letter-spacing: 0.01em;
  color: #1e1e1e;
  mix-blend-mode: normal;
}
.landing_slider-right-desc-desc {
  /* text-align: start; */
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;

  width: 95%;

  color: #1e1e1e;
  mix-blend-mode: normal;
}
.landing_slider-right-desc-desc ol {
  margin-left: -18px;
}
.landing_slider-right-img-block {
  width: 56px;
  height: 56px;
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* active btn block */
.landing_active-btn-bloc {
  width: 100%;
  background-image: url("../../assets/icons/test1back.webp");
  padding-top: 105px;
  padding-bottom: 105px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing_active-btn-cont {
  width: 93%;

  background-color: #fff;
  border-radius: 15px;
}
.landing_slider-header.active_block {
  padding-top: 0px;
  margin-left: 0;
}
.landing_slider-desc.active_block {
  margin-left: 0;
}
.landing_active-btn {
  padding: 20px;

  background: #1a73e8;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 125%;
  color: #ffffff;
  cursor: pointer;
}
.landing_active-btn-flex {
  display: flex;
  width: 84%;
  margin-left: 8%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* footer */
.landing_footer {
  width: 100%;
  background: #1b1a1a;
  padding-top: 117px;
}
.landing_footer-left-img {
  width: 41%;
  margin-top: -30px;
  margin-left: -2%;
}
.landing_footer-left {
  width: 50%;
}
.landing_footer-left-privacy-text {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  color: #ffffff;
  margin-top: 10px;
}
.landing_footer-left-privacy-block {
  margin-top: -50px;
}
.landing_footer-left-contact-text {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  margin-top: 10px;

  /* or 194% */

  color: #ffffff;
  margin-left: 14px;
  margin-top: -0px;
}
.landing_footer-left-contact-cont {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.landing_footer-left-contact-block {
  margin-top: 40px;
}
.landing_footer-right-header {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 600;
  font-size: 2.4vw;

  /* or 78% */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;
}
.landing_footer-right {
  width: 40%;
}
.landing_footer-right-input-cont {
  width: 45%;
}
.landing_footer-right-input {
  outline: none;
  width: 100%;
  /* height: 50px; */
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  background-color: #1b1a1a;
  padding-left: 5%;
  color: #fff;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  outline: none;
}
.landing_footer-right-input::placeholder {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  line-height: 27px;
  /* or 169% */

  color: rgba(255, 255, 255, 0.7);
}
.landing_footer-right-inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 57px;
}

.landing_footer-right-textarea {
  width: 100%;

  border: 1px solid #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  background-color: #1b1a1a;
  padding-left: 2.5%;
  padding-top: 20px;
  padding-bottom: 100px;
  color: #fff;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin-top: 38px;
  outline: none;
}
.landing_footer-right-textarea-cont {
  width: 100%;
}
.landing_footer-right-textarea::placeholder {
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  line-height: 27px;
  /* or 169% */

  color: rgba(255, 255, 255, 0.7);
}
.landing_footer-right-btn {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7vw;
  /* or 36px */

  text-transform: uppercase;

  color: #1e1e1e;
  margin-top: 31px;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}
.landing_footer-cont {
  width: 88%;
  display: flex;
  margin-left: 6%;
  justify-content: space-between;
}
.landing_slider-purple-img::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.landing_slider-purple-img {
  width: 18%;
  margin-left: 25px;
  height: fit-content;
  margin-top: -1%;
}
.landing_slider-purple-img.right {
  margin-left: 0;
}
.landing_footer-left-contact-img {
  width: 4%;
}
.landing-contact-btn-cont {
  width: 40%;

  /* position: fixed; */
  bottom: 50px;
  right: 3.5%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
}
.landing-contact-btn--img {
  width: 50%;
  cursor: pointer;
}
.landing-contact-btn--link {
  width: 50%;
}
.landing_video-slider-text-more {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 6%;
  flex-direction: column;
  align-items: center;
}
.landing_slider-center-block-mob {
  background-color: #fff;
}
.landing_video-slider-text-more-text {
  background: linear-gradient(90.05deg, #fdb2f9 0.05%, #8253d8 98.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 12px;
}
.landing_video-slider-text-more-img {
  width: 30%;
  cursor: pointer;
}
.landing_video-play-img {
  position: absolute;
  width: 10%;
  left: 40%;
  z-index: 999;
  top: 40%;
  cursor: pointer;
}

.landing-contact-popup {
  width: 22%;

  position: fixed;
  right: 8.6%;
  bottom: 20px;
  z-index: 999999;
  display: flex;
}

.landing-contact-popup-cont {
  width: 60%;
  height: auto;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: inset 24.1px -24.1px 24.1px rgba(194, 194, 194, 0.05),
    inset -24.1px 24.1px 24.1px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(24.1px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  color: #ffffff;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.landing-contact-popup-close-img {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.landing-contact-popup-cont.close {
  opacity: 0;
}
.landing_ban_contacts-cont {
  width: 12%;
  position: fixed;
  z-index: 99999;
  right: 1%;
  bottom: 10px;
}
.landing_ban_contacts-close {
  width: 40%;
}
.landing_ban_contacts-close-cont {
  background: #f4f4f4;
  box-shadow: 1.04095e-15px 17px 34px rgba(51, 51, 51, 0.1455),
    inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
    inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
  border-radius: 35px;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -3%;
}
.landing_ban_contacts-close-cont::before {
  content: "";
  display: block;
  padding-top: 100%;
  /* Равная высота блока (в примере 100%) */
}
.landing_ban_contacts-text-block {
  background: #f4f4f4;
  box-shadow: 1.04095e-15px 17px 34px rgba(51, 51, 51, 0.1455),
    inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
    inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
  width: 100%;

  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.landing_ban_contacts-text {
  width: 90%;
  font-size: 0.8vw;
  padding-top: 8%;
  padding-bottom: 8%;
  font-family: "MyWebFont";
  font-style: normal;
  padding-left: 9%;
}
.landing_ban_contacts-img-block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}
.landing_ban_contacts-img {
  width: 50%;
}
.landing_ban_contacts-img-cont {
  background: #f4f4f4;
  box-shadow: 1.04095e-15px 17px 34px rgba(51, 51, 51, 0.1455),
    inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
    inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
  border-radius: 35px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.landing_ban_contacts-img-cont::before {
  content: "";
  display: block;
  padding-top: 100%;
  /* Равная высота блока (в примере 100%) */
}
.landing_ban_contacts-img-cont.right {
  margin-left: 5px;
}

.landing_video-slider-flip-text-block {
  color: #fff;
  font-family: "MyWebFont";
  font-style: normal;
  font-weight: 600;
  font-size: 1.1vw;
  margin-left: 5%;
  text-transform: uppercase;
}
.landing_video-slider-flip-line {
  width: 90%;
  height: 2px;
  background: #7146a9;
  border-radius: 60px;
  margin-left: 5%;
  margin-top: 5px;
}
.landing_video-slider-flip-text-cont {
  width: 90%;
  margin-left: 5%;
  text-align: justify;
  font-size: 1.45vw;
  font-family: "MyWebFont";
  font-style: normal;
  margin-top: 2%;
}
.landing-video-cont-flip-span-purple {
  color: #954bac;
}
.landing-video-cont-flip-span-white {
  /* font-size: 1.55vw; */

  color: #fff;
}
.landing_video-slider-flip-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  cursor: pointer;
}

/* animation */
.flip-vertical-right {
  -webkit-animation: flip-vertical-right 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}
.landing_video-mobile-social-rect-cont {
  display: none;
}
.landing_video-cont-btn-mob-cont {
  display: none;
}
.landing_slider-content-block-mob {
  display: none;
}
.footer-landing-line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-top: 50px;
}
.footer-landing-bottom-cont {
  width: 100%;
  height: 50px;
  display: flex;
}
.footer_social-burn.landing {
  margin-left: 0;
  margin-top: 15px;
}
.footer-landing-bottom-cont-block {
  display: flex;
  width: 90%;
  margin-left: 5%;
  justify-content: space-between;
}
.landing_header-burger-mob {
  display: none;
}
.landing_header-burger-desc {
  cursor: pointer;
}
.landing_header-link-cont {
  display: flex;
  justify-content: space-between;
  width: 30%;
}
.landing_header-link {
  color: #fff;
}
.landing_contact-cont-mob {
  display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-17 9:20:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}
@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}
.landing-fadein {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-17 10:42:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes landing-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes landing-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 1000px) {
  .landing_header-block {
    height: 80px;
  }
  .landing_header-cont {
    height: 80px;
  }
  .landing_header-logo {
    width: 40%;
    margin-top: 40px;
  }
  .landing_header-logo-img {
    width: 100%;
  }
  .landing_header-btnCont {
    flex-direction: row-reverse;
  }
  .landing_action-btn {
    margin-left: 0;
    font-size: 3vw;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 20px;
  }
  .landing_header-burger-btn {
    width: 6vw;
  }
  .landing_video-cont-header {
    font-size: 7vw;
    padding-top: 50px;
    width: 80%;
  }
  .landing_video-cont-desc {
    font-size: 4vw;
    background: linear-gradient(90deg, #fdb2f9 8.23%, #9228c5 93.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .landing_video-mobile-social-rect-cont {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 55px;
    position: relative;
  }
  .landing_video-mobile-social-rect {
    background: linear-gradient(
      180deg,
      rgba(130, 83, 216, 0.85) 0%,
      rgba(208, 93, 222, 0.85) 100%
    );
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing_video-mobile-social-rect::before {
    content: "";
    display: block;
    padding-top: 100%; /* Равная высота блока (в примере 100%) */
  }
  .landing_video-mobile-social-rect-line {
    width: 90%;
    margin-left: 2%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 49.5%;
    z-index: 0;
  }
  .landing_video-mobile-social-rect-black {
    background-color: #1b1a1a;
    width: 13%;
    z-index: 9999;
  }
  .landing_video-data-cont {
    flex-direction: column-reverse;
    margin-left: 0;
  }
  .landing_video-slider-cont {
    width: 80%;
    margin-left: 10%;
    height: 500px;
    margin-top: 40px;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(72.3px);
  }
  .landing_video-slider-text-title {
    font-size: 5vw;
  }
  .landing_video-slider-text-li {
    font-size: 4vw;
  }
  .landing_video-data-block {
    width: 90%;
    margin-left: 5%;
  }
  .landing_video-rectangle-block {
    display: none;
  }
  .landing_video-slider-flip-text-block {
    font-size: 4.5vw;
  }
  .landing_video-slider-flip-text-cont {
    font-size: 5vw;
  }
  .landing_video-cont-btn-mob-cont {
    display: flex;
    justify-content: center;
  }
  .landing_video-cont-btn-mob {
    font-family: "MyWebFont";
    font-style: normal;
    font-weight: 700;
    font-size: 4vw;
    line-height: 125%;
    /* or 20px */

    text-align: justify;

    color: #ffffff;
    margin-top: 120px;
    background: #1a73e8;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .landing_slider-header {
    padding-top: 88px;
    font-size: 6vw;
    margin-left: 5%;
    font-weight: 500;
  }
  .landing_slider-desc {
    font-size: 6vw;
    margin-left: 5%;
    font-weight: 500;
  }
  .landing_slider-content-block {
    flex-direction: column;
  }
  .landing_slider-content-block {
    display: none;
  }
  .landing_slider-content-block-mob {
    display: flex;
    flex-direction: column;
  }
  .landing_slider-left-cont-mob {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    margin-left: 2%;
    margin-top: 20px;
  }
  .landing_slider-left-text-title-mob {
    font-family: "MyWebFont";
    font-style: normal;
    font-weight: 800;
    font-size: 5vw;
    margin-top: 10px;
  }
  .landing_slider-left-desc-desc-mob {
    font-family: "MyWebFont";
    font-style: normal;
    font-weight: 500;
    font-size: 4vw;
    margin-top: 10px;
  }
  .landing_slider-center-img-mob {
    width: 80%;
    margin-left: 10%;
    margin-top: 76px;
    padding-bottom: 70px;
  }
  .landing_active-btn-bloc {
    margin-top: 104px;
  }
  .landing_active-btn-flex {
    flex-direction: column;
  }
  .landing_active-btn {
    margin-top: 75px;
    width: 80%;
    font-size: 4vw;
  }
  .landing_active-btn-cont {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .landing_footer-cont {
    flex-direction: column-reverse;
    width: 100%;
    margin-left: 0;
  }
  .landing_footer-right-header {
    font-size: 5vw;
    width: 80%;
    margin-left: 5%;
  }
  .landing_footer-right {
    width: 100%;
  }
  .landing_footer-right-inputs {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }
  .landing_footer-right-input {
    margin-top: 20px;
    padding-left: 10%;
    width: 100%;
    font-size: 3vw;
  }
  .landing_footer-right-input::placeholder {
    font-size: 3vw;
  }
  .landing_footer-right-input-cont {
    width: 70%;
    margin-left: -5%;
  }
  .landing_footer-right-textarea-cont {
    width: 70%;
    margin-left: 12%;
    margin-top: -15px;
  }
  .landing_footer-right-textarea::placeholder {
    font-size: 3vw;
  }
  .landing_footer-right-textarea {
    padding-bottom: 40px;
    font-size: 3vw;
    padding-left: 10%;
  }
  .landing_footer-right-btn {
    width: 40%;
    margin-left: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 40px;
    font-size: 4vw;
  }
  .landing_footer-left {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
  .landing_footer-left-img {
    width: 60%;
    margin-top: 20px;
  }
  .landing_footer-left-privacy-block {
    margin-top: 10px;
  }
  .landing_footer-left-contact-text {
    font-size: 3vw;
  }
  .landing_footer {
    padding-top: 87px;
  }
  .landing_footer-left-contact-img {
    width: 5vw;
  }
  .landing_footer-left-privacy-text {
    font-size: 3vw;
  }
  .landing_footer-left-privacy-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .landing_ban_contacts-cont {
    width: 45%;
    right: 5%;
    bottom: 50px;
  }
  .landing_ban_contacts-text {
    font-size: 3vw;
  }
  .footer_social-cont.mob {
    display: flex;
    margin-left: 20px;
  }
  .landing_header-burger-desc {
    display: none;
  }
  .landing_header-burger-mob {
    display: block;
  }
  .landing_header-link-cont {
    display: none;
  }
  .landing_video-data-cont {
    margin-top: 40px;
  }
  .landing_video-play-img {
    width: 20%;
    top: 30%;
  }
  .landing_ban_contacts-cont {
    display: none;
  }
  .landing_contact-cont-mob {
    display: block;
    background-image: url("../../assets/icons/backgroundImg.webp");
    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .landing_contact-block-mob {
    width: 80%;
    margin-left: 10%;
  }
  .landing_contact-close-block-mob {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .landing_contact-close-img-mob {
    width: 8%;
    background: #f8f8f8;
    box-shadow: 1.04095e-15px 17px 34px rgba(51, 51, 51, 0.1455),
      inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
      inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .landing_contact-close-img-mob::before {
    content: "";
    display: block;
    padding-top: 100%; /* Равная высота блока (в примере 100%) */
  }
  .landing_contact-close-img-mo {
    width: 50%;
  }
  .landing_contact-text-cont-mob {
    width: 100%;
    height: 50px;
    margin-top: 11px;
    background: #f8f8f8;
    box-shadow: 1.04095e-15px 17px 25px rgba(51, 51, 51, 0.1455),
      inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
      inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing_contact-text-mob {
    font-size: 3vw;
    font-weight: 500;
  }
  .landing_contact-btn-cont-mob {
    width: 46%;
    margin-left: 27%;
    display: flex;
    justify-content: space-between;
  }
  .landing_contact-btn-mob {
    width: 42.5%;

    background: #f4f4f4;
    box-shadow: 1.04095e-15px 17px 34px rgba(51, 51, 51, 0.1455),
      inset -2.61394e-16px -4.26889px 4.26889px #dddddd,
      inset 2.61394e-16px 4.26889px 4.26889px #ffffff;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing_contact-btn-mob::before {
    content: "";
    display: block;
    padding-top: 100%; /* Равная высота блока (в примере 100%) */
  }
  .landing_contact-btn-mob-img {
    width: 50%;
  }
  .landing_contact-btn-mob-img.call {
    width: 65%;
  }
  .landing_contact-intent-mob {
    width: 100%;
    height: 22px;
  }
  .landing_video-slider-flip-cont {
    height: 90%;
    padding-top: 5%;
  }
  .landing_slider-purple-img-mob {
    width: 30%;
  }
}
