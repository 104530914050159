.privacy {
  padding-top: 130px;
  width: 60%;
  margin-left: 20%;
}
.privacy div {
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope";
}
.privacy ul li {
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope";
}
