.price_header {
  font-size: 36px;
  text-align: center;
  padding-top: 130px;
  font-weight: 700;
  font-family: "Manrope";
}
.price_data-item-cont {
  width: 32%;
  height: 1000px;
  border: 1px solid #000;
}
.price_data-cont {
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
}
.price_data-side-cont {
  width: 32%;
  box-shadow: 3px -2px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
}
.price_data-center-cont {
  width: 32%;
  box-shadow: 3px -2px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-top: -69px;
}
.price_data-side-header-cont {
  width: 100%;
  background: linear-gradient(226.05deg, #c4a4ff 8.17%, #8754d9 98.15%);
  border-radius: 35px 35px 0px 0px;
  height: 393px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.price_data-center-header-cont {
  width: 100%;
  background: linear-gradient(226.05deg, #f7b4ff 8.17%, #d05dde 98.15%);

  border-radius: 35px 35px 0px 0px;
  height: 462px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.price_data-side-header {
  font-size: 2.5vw;
  font-weight: 700;
  color: #fff;
  padding-top: 36.75px;
  font-family: "Manrope";
}
.price_data-ceter-header {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  padding-top: 105.75px;
  font-family: "Manrope";
}
.price_data-side-header-price {
  font-size: 4vw;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 40px;
  font-family: "Manrope";
}
.price_data-side-header-price-icon {
  font-size: 48px;
  font-weight: 400;
  margin-top: -75px;
  font-family: "Manrope";
}
.price_data-side-header-price-mo {
  font-size: 36px;
  font-weight: 400;
  font-family: "Manrope";
}
.price_data-side-header-desc {
  margin-top: 35.35px;
  font-size: 1.3vw;
  font-weight: 400;
  color: #fff;
  font-family: "Manrope";
}
.price_data-side-header-valid {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  font-family: "Manrope";
}
.price_data-side-header-btn {
  width: 231px;
  height: 39.75px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
  font-family: "Manrope";
}
.price_data-side-header-btn:hover {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  transition: 0.5s;
}
.pice_data-bottom-cont {
  width: 100%;
  height: 570px;
  background-color: #fff;
  border-radius: 35px;
}
.price_item-cont {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  margin-top: 33.75px;
}
.price_item-img {
  width: 20px;
  height: 14px;
}
.price_item-text {
  font-size: 1.1vw;
  font-weight: 400;
  margin-left: 15px;
  font-family: "Manrope";
}
.price_star-cont {
  position: absolute;
  top: -10px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}
.price_star-img {
  width: 28px;
}
.price_data-ceter-header-text {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 107.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 27px;
  position: absolute;
  top: 28.5px;
  font-family: "Manrope";
}
.price_header-desc {
  font-family: "Manrope";
  margin-top: 20px;
  text-align: center;
}
@media only screen and (min-width: 1720px) {
  .pice_data-bottom-cont {
    height: 700px;
  }
}
@media only screen and (max-width: 800px) {
  .price_header {
    margin-left: 5%;
    text-align: left;
    font-size: 4.2vw;
  }
  .price_header-desc {
    margin-left: 5%;
    font-size: 3.8vw;
    text-align: left;
  }
  .price_data-cont {
    flex-wrap: wrap;
    width: 90%;
    margin-left: 5%;
    margin-top: 74px;
  }
  .price_data-side-cont {
    width: 100%;
    margin-top: 20px;
    order: 2;
  }
  .price_data-center-cont {
    width: 100%;
    margin-top: 20px;
  }
  .price_data-side-header {
    font-size: 7.2vw;
  }
  .price_data-side-header-price {
    font-size: 7.2vw;
  }
  .price_data-side-header-desc {
    font-size: 4.2vw;
  }
  .price_item-text {
    font-size: 3.8vw;
  }
  .price_data-side-header-price-icon {
    font-size: 7vw;
    margin-top: -30px;
  }
}
