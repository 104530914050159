.mobile_cont {
  width: 100%;
  height: 100%;
  background-color: #fffcf8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
}
.mobile_link-cont {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  align-items: center;
}
.mobile_link {
  font-size: 20px;
  color: #000;
  text-align: center;

  border: 1px solid #000;
  width: 60%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.mobile_btn {
  border: 1px solid #2e2eff;
  width: 130px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #2e2eff;
  background-color: #fff;
}
.mobile_btn-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.header_burger-close {
  width: 30px;
}
