.header_cont {
  background: #040404;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 86px;
  position: fixed;
  width: 100%;
  z-index: 999999;
}
.header_content-cont {
  width: 96%;
  height: 86px;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_logo {
  width: 180px;
  margin-left: -10%;
  margin-top: 35px;
}

.header_logo-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header_login-img {
  width: 35px;
  height: 35px;
}
.header_item-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  margin-left: 76px;
}
.header_login-cont {
  display: flex;
  align-items: center;
}
.header_button {
  text-transform: capitalize;
  font-family: "Manrope";
  background: radial-gradient(
      114.29% 482.53% at 34.54% 5.36%,
      #8754d9 0%,
      #d05dde 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-left: 38px;
  cursor: pointer;
  font-size: 1.2vw;
}
.header_login-text {
  margin-left: 5px;
  color: #fff;
  font-family: "Manrope";
  opacity: 0;
}
.header_item {
  cursor: pointer;
  color: #fff;
  font-family: "Manrope";
  font-size: 1.1vw;
}
.header_item:hover {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}

.header_burger {
  display: none;
}
.header_line {
  height: 42px;
  border-left: 1px solid #fff;
  margin-left: 38px;
  opacity: 0;
}
.header_item.active {
  background: linear-gradient(183.44deg, #8253d8 2.83%, #d05dde 77.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media only screen and (max-width: 1000px) {
  .header_item-cont {
    display: none;
  }
  .header_login-cont {
    display: none;
  }
  .header_content-cont {
    height: 80px;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
  }
  .header_burger {
    width: 100%;
    display: block;
  }
  .header_cont {
    height: 80px;
  }
  .header_logo-cont {
    width: 40%;
    margin-left: 5%;
  }
  .header_burger-cont {
    width: 10%;
  }
  .header_logo {
    width: 100%;
    margin-left: -50%;
    margin-top: 35px;
  }
}
