.home_h-text {
  font-size: 36px;
  margin-left: 2%;
  line-height: 74.2px;
  font-weight: 700;
  padding-top: 115px;
  font-family: "Manrope";
}

.home_lateral-gif-cont {
  width: 33%;
  height: 600px;
}

.home_gif-cont {
  display: flex;
  margin-left: 2%;
  justify-content: space-between;
  width: 96%;
  margin-top: 20px;
}

.home_center-gif-cont {
  width: 76%;
}

.home_lateral-gif {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_lateral-gif-text {
  line-height: 1.4em;
  font-size: 20px;
  font-family: "Manrope";
  text-align: center;
}

.home_center-gif-text {
  line-height: 1.4em;
  font-size: 20px;
  text-align: center;
  font-family: "Manrope";
}

.home_lateral-img {
  height: 100%;
}
.home_lateral-img-right {
  height: 110%;
}
.home_center-img {
  width: 68%;
}

.home_more-btn {
  width: 150px;
  height: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #040404;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
  font-family: "Manrope";

  /* or 20px */

  background: linear-gradient(180deg, #d05dde 0%, #8754d9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  position: absolute;
}
.home_more-btn:hover {
  border: 2px solid #040404;
}
.home_more-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.home_partners-cont {
  width: 80%;
  margin-left: 10%;
  margin-top: 100px;
  border-bottom: 1px solid #000;
}

.home_partner-img {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
}

.home_partners-text {
  font-size: 20px;
  font-weight: 600;
  font-family: "Manrope";
}

.home_blue-screen {
  width: 280px;
  height: 300px;
  background-color: rgb(154, 204, 231);
  border-radius: 10px;
}

.home_screens-cont {
  margin-top: 120px;
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_blue-screen-text {
  line-height: 27.2px;
  font-weight: 400;
  width: 80%;
  margin-left: 10%;
  padding-top: 50px;
}

.home_slide {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home_slide-cont {
  width: 327px;
  height: 280px;
}

.home_slide-text {
  font-size: 1rem;
  line-height: 28px;
  font-family: "Manrope";
}

.home_social-cont-text {
  margin-top: 170px;
  line-height: 1.4em;
  text-align: center;
  font-size: 20px;
  font-family: "Manrope";
}

.home_line-vertical {
  width: 600px;
  border-top: 1px solid #000;
  height: 150px;
  display: flex;
  flex-direction: row;
}

.home_line-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 0;
}

.home_line-left {
  width: 30%;
  border-right: 1px solid #000;
  height: 100%;
}

.home_line-center {
  width: 40%;
  height: 100%;
}

.home_line-right {
  width: 30%;
  border-left: 1px solid #000;
  height: 100%;
}

.home_line-bottom {
  width: 280px;
  height: 139px;
  border-top: 1px solid #000;
}

.home_line-bottom-center {
  width: 50%;
  height: 100%;
  border-right: 1px solid;
}

.home_social-square-item {
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, 0.6));
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #fffcf8;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_social-square-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.home_social-square-top {
  display: flex;
  width: 650px;
  justify-content: space-between;
  margin-top: 10px;
}

.home_social-square-bottom {
  display: flex;
  width: 320px;
  justify-content: space-between;
  margin-top: 70px;
}

.home_social-square-line {
  width: 70%;
  border-top: 1px solid #000;
  margin-top: 100px;
}

.home_social-square-text-container {
  display: flex;
  justify-content: space-between;
}

.home_social-square-item-cont-text {
  width: 100%;
  height: 280px;
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, 0.6));
  border-radius: 10px;
}

.home_social-square-item-text {
  width: 30%;
}

.home_social-square-item-cont-header {
  text-align: center;
  padding-top: 20px;
}

.home_social-square-item-text-desc {
  width: 90%;
  margin-left: 5%;
}

.home_social-img {
  width: 50px;
}

.home_header-about-small {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  margin-top: 150px;
  padding-bottom: 50px;
  font-family: "Manrope";
}

.home_header-about {
  font-size: 55px;
  line-height: 71.5px;
  text-align: center;
  font-weight: 300;
  font-family: "Manrope";
}

.home_about-img-cont {
  position: relative;
}

.home_about-img {
  width: 700px;
  border-radius: 10px;
}

.home_about-cont {
  width: 96%;
  margin-left: 2%;
  margin-top: 50px;
  display: flex;
}

.home_about-img-text {
  position: absolute;
  top: 100px;
  width: 300px;
  left: 190px;
}

.home_about-text-header {
  font-size: 45px;
  font-family: "Manrope";
}

.home_about-text-sub-header {
  line-height: 1.4em;
  font-size: 25px;
  margin-top: 20px;
  font-family: "Manrope";
}

.home_about-text {
  margin-top: 10px;
}

.home_about-text-cont {
  margin-left: 130px;
}

.home_about-btn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #000;
  margin-top: 15px;
  cursor: pointer;
}

.home_butjet-text-cont {
  width: 30%;
}

.home_butjet-block {
  width: 80%;
  margin-left: 10%;
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
}

.home_butjet-img {
  width: 330px;
}

.home_butjet-blue-block {
  width: 25%;
  background-color: rgb(154, 204, 231);
  border-radius: 5px;
}

.home_butjet-blue-text {
  width: 90%;
  margin-left: 5%;
  padding: 10px;
}
.home_gradient-cont {
  width: 100%;

  height: 25%;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.75) 0%,
    rgba(208, 93, 222, 0.75) 100%
  );
  border-radius: 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.8vw;
  line-height: 98.5%;
  font-family: "Manrope";
}
.home_screen-img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 35px;
}
.home_screen-imgs-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2.5%;
}
.home_screen-text {
  font-size: 1.2vw;
  width: 90%;
  margin-top: 8px;
  font-family: "Manrope";
}
.home_screen-cont {
  display: flex;
  width: 100%;
}
.home_screen-img-right {
  width: 100%;
  border-radius: 30px;
  height: 87%;
  object-fit: contain;
}
.home_screen-right-screen {
  width: 25%;
  margin-left: 1%;
}

.home_screen-block {
  display: flex;
  margin-left: 10%;
  width: 80%;
  margin-top: 189px;
  position: relative;
  justify-content: space-between;
}
.home_soc-logo-img {
  width: 175px;
}
.home_soc-logo-cont {
  z-index: 99999 !important;
  position: absolute !important;
}
.home_soc-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.line1 {
  height: 310px;
  border-left: 1px solid #d2d2d2;
  width: 0px;
  position: absolute;
  left: 81px;
  top: -25px;
  z-index: 1;
}
.line2 {
  height: 210px;
  border-left: 1px solid #d2d2d2;
  width: 0px;
  position: absolute;
  left: 81px;
  top: -25px;
  z-index: 99;
  transform: rotate(60deg);
}
.line3 {
  height: 210px;
  border-left: 1px solid #d2d2d2;
  width: 0px;
  position: absolute;
  left: 81px;
  top: -25px;
  z-index: 0;
  transform: rotate(-60deg);
}
.home_soc-logo-cont {
  position: relative !important;
}
.backGround {
  width: 65px;
  height: 75px;
  background-color: #fff;
  position: absolute;
  left: 50px;
  top: 40px;
  z-index: 105;
}
.home_rectangle-img {
  width: 100px;
}
.home_rectangle1-cont {
  position: absolute;
  left: 32px;
  top: -120px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_rectangle2-cont {
  position: absolute;
  left: 168px;
  top: -52px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_rectangle3-cont {
  position: absolute;
  left: 168px;
  top: 104px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_rectangle4-cont {
  position: absolute;
  left: -105px;
  top: 104px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_rectangle5-cont {
  position: absolute;
  left: -105px;
  top: -52px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_rectangle-soc-img {
  position: absolute;
  width: 50%;
}
.home_lateral-video {
  width: 30%;
}
.home_video-cont {
  margin-left: 10%;
}
.home_social-cont-text {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  font-family: "Manrope";
}
.home_video-block {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 125px;
}
.home_video-img {
  width: 61%;
  border-radius: 30px;
  cursor: pointer;
}
.home_desc-video-block {
  width: 61%;
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: center;
}
.home_desc-video-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.home_desc-video-block-line1 {
  width: 1px;
  height: 37.5px;
  border-left: 2px solid #fff;
}
.home_desc-video-block-line2 {
  width: 100%;
  height: 74px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 61%;
}
.home_desc-video-block-line3 {
  width: 50%;
  height: 74px;
  border-right: 2px solid #fff;
  margin-left: -1px;
}
.home_desc-video-data-cont {
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
}
.home_desc-video-data-block {
  width: 26%;

  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.75) 0%,
    rgba(208, 93, 222, 0.75) 100%
  );
  backdrop-filter: blur(7.5px);
  border-radius: 35px;
  padding: 20px;
  position: relative;
}
.home_desc-video-data-block-header-gr {
  width: 80%;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.15) 0%,
    rgba(208, 93, 222, 0.15) 100%
  );
  filter: blur(37.5px);
  margin-left: 5%;
  position: absolute;
  z-index: -1;
  top: 40px;
}
.home_desc-video-data-block-header {
  font-size: 1.8vw;
  font-weight: 400;
  text-align: center;
  margin-top: 29.25px;
  font-family: "Manrope";
  color: #ffffff;
}
.home_desc-video-data-block-desc {
  width: 96%;
  text-align: justify;
  margin-left: 2%;
  margin-top: 24.75px;
  font-size: 1.25vw;
  font-weight: 400;
  font-family: "Manrope";
  color: #fff;
}
.home_why-pix-header {
  margin-top: 160px;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.55) 0%,
    rgba(166, 88, 219, 0.55) 46.03%,
    rgba(208, 93, 222, 0.55) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 36px;
  font-weight: 400;
  font-family: "Manrope";
}
.home_why-pix-cont {
  margin-left: 10%;
  /* width: 100%; */
}
.home_why-pix-desc {
  font-size: 36px;
  color: #000;
  font-weight: 700;
  margin-top: 25.5px;
  font-family: "Manrope";
}
.home_why-pix-right-content {
  position: relative;
  width: 50%;
  height: 100%;
}
.home_why-pix-right-img {
  width: 100%;
  border-radius: 35px;
}
.home_why-pix-content-cont {
  margin-top: 81px;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.home_why-pix-right-text-cont {
  width: 100%;
  background: linear-gradient(
    179.49deg,
    rgba(217, 217, 217, 0) 25.65%,
    rgba(255, 255, 255, 0.2) 39.16%,
    rgba(255, 255, 255, 0.96) 60.15%,
    #ffffff 93.93%
  );
  height: 171.5px;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 35px 35px;
}
.home_why-pix-right-text {
  margin-top: 115.25px;
  text-align: center;
  width: 90%;
  font-size: 12px;
  margin-left: 5%;
  font-weight: 500;
  font-family: "Manrope";
}
.home_why-pix-left-cont-header {
  font-size: 2.2vw;
  font-weight: 700;
  font-family: "Manrope";
}
.home_why-pix-left-cont {
  margin-right: 16px;
}
.home_why-pix-left-cont-desc {
  font-weight: 700;
  font-size: 1.2vw;
  margin-top: 4px;
  font-family: "Manrope";
}
.home_why-pix-left-text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 32.25px;
  line-height: 24px;
  font-family: "Manrope";
}
.home_why-pix-left-item-num-cont {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #302f2e;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope";
}
.home_why-pix-left-item-cont {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.home_why-pix-left-item-text {
  margin-left: 10.5px;
  font-size: 1.1vw;
  font-weight: 400;
  font-family: "Manrope";
}
.home_btn {
  width: 150px;
  height: 39.75px;
  background-color: #ffffff;
  border: 1px solid #040404;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background: linear-gradient(180deg, #d05dde 0%, #8754d9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 800;
  cursor: pointer;
  font-family: "Manrope";
}
.home_btn:hover {
  border: 2px solid #040404;
}
.btn_cont {
  margin-top: 40.75px;
  position: absolute;
}
.home_budjet-block {
  width: 90%;

  background-color: #000;
  border-radius: 35px;
  margin-top: 150px;
  margin-left: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.home_budjet-header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4vw;
  color: #fff;
  text-align: center;
}
.home_budjet-desc {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 1.4vw;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}
.home_budjet-purple-block-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
}
.home_budjet-purple-left-text {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 1.4vw;
  margin-right: 10px;
}
.home_budjet-purple-right-text {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 1.4vw;
  margin-left: 10px;
}
.home_budjet-data-cont {
  width: 90%;
  margin-left: 5%;
  margin-top: 50px;
  display: flex;
  position: relative;
}
.home_budjet-text-cont {
  width: 60%;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.75) 0%,
    rgba(208, 93, 222, 0.75) 100%
  );
  height: inherit;
  border-top-right-radius: 1.5vw;
  border-bottom-right-radius: 1.5vw;
}
.home_budjet-img-cont {
  width: 40%;
}
.home_budjet-img {
  width: 100%;
  border-top-left-radius: 1.5vw;
  border-bottom-left-radius: 1.5vw;
}
.home_budjet-data-line {
  width: 100%;
  position: absolute;
  height: 3.5px;
  background-color: #000;
  bottom: 0;
}
.home_budjet-text-block {
  margin-top: 3%;
}
.home_budjet-text {
  margin-top: 2%;
  width: 90%;
  margin-left: 5%;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;

  /* or 150% */

  text-align: justify;

  color: #ffffff;
}
.home_budjet-btn {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 1.1vw;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  /* or 16px */

  color: #ffffff;
  cursor: pointer;
}
.home_budjet-btn:hover {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  transition: 0.5s;
}

.home_budjet-btn-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.home_promo-left-promo-video {
  width: 29%;
  border-radius: 1.5vw;
  margin-left: 15px;
  border: 6px solid #000;
}
.home_promo-block {
  margin-left: 10%;
  margin-top: 155.25px;
  display: flex;
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.home_promo-right-header {
  font-size: 30px;
  font-weight: 700;
  color: #302f2e;
  font-family: "Manrope";
}
.home_promo-right-desc {
  font-size: 18px;
  font-weight: 700;
  margin-top: 4px;
  font-family: "Manrope";
}
.home_promo-right-item-img {
  width: 36px;
}
.home_promo-right-item {
  display: flex;
  align-items: center;
}
.home_promo-right-item-text {
  margin-left: 12px;
  width: 150px;
}
.home_promo-right-item-block {
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-top: 65.25px;
}
.home_promo-right-item-cont {
  margin-top: 65.25px;
}
.home_promo-right-btn-cont {
  position: absolute;
  bottom: 0;
}

.home_comment-cont {
  width: 60%;
  margin-left: 20%;
  height: 373px;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.65) 0%,
    rgba(208, 93, 222, 0.65) 100%
  );
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 35px 100px 35px 35px;
  margin-top: 166.5px;
  position: relative;
}
.home_comment-header {
  font-size: 1.1vw;
  font-weight: 700;
  padding-top: 55.5px;
  margin-left: 32.25px;
  font-family: "Manrope";
}
.home_comment-text {
  width: 90%;
  margin-left: 32.25px;
  margin-top: 17.25px;
  font-size: 1vw;
  font-weight: 400;
  font-family: "Manrope";
}
.home_comment-bottom-cont {
  margin-left: 32.25px;
  margin-top: 70px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
}
.home_comment-user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_comment-avatar {
  width: 49.5px;
  height: 49.5px;
  border-radius: 100px;
  background: linear-gradient(180deg, #d05dde 0%, #8253d8 100%);
}
.home_comment-user-desc-cont {
  margin-left: 16.5px;
}

.home_comment-user-name {
  color: #3a1212;
  font-size: 18px;
  font-weight: 700;
  font-family: "Manrope";
}
.home_comment-user-desc {
  margin-top: 7px;
  margin-top: 6.75px;
  font-size: 13px;
  color: #504747;
  font-family: "Manrope";

  width: 250px;
}
.home_comment-arrow-enabled {
  width: 12px;
  height: 20px;
  cursor: pointer;
}
.home_comment-arrow-disabled {
  width: 12px;
  height: 26px;
  cursor: pointer;
  margin-left: 22px;
}
.video_video-block {
  width: 86%;
  display: flex;
  margin-left: 7%;
}
.video_video-cont {
  width: 24.5%;
  position: relative;
  display: flex;
  justify-content: center;
}
.video_video-item {
  width: 100%;
  height: 100%;
}
.video_video-center-cont {
  width: 52%;
  margin-top: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video_video-item-center {
  width: 100%;
  /* height: 387px; */

  object-fit: contain;
  height: 90%;
  margin-top: -22px;
}
.video_video-text {
  text-align: center;
}
.video_video-text.side {
  position: absolute;
  top: 30px;
  font-size: 1.2vw;
  font-weight: 400;
  font-family: "Manrope";
  font-family: "Manrope";
}
.video_video-text.center {
  top: -60px;
  position: absolute;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 400;
  font-family: "Manrope";
}
.home_screen-img-cont {
  width: 49%;
}
.home_promo-right-block {
  position: relative;
  margin-left: -15%;
}
.popup-video-cont {
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;

  /* transform: translate(-50%, -50%); */
  backdrop-filter: blur(8px);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.popup-video-item {
  height: 600px;
}
.close_popup {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 100px;
  top: 20px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 100px;
}
.home_promo-left-block {
  width: 80%;
  margin-left: -8%;
}
.home_partners-block-img {
  width: 100%;
  /* height: 70px; */
}

.home_partners-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
}
.home_prev-block {
  width: 90%;

  border-radius: 35px;
  margin-left: 5%;
  padding-bottom: 60px;
  margin-top: 120px;
  padding-bottom: 34px;
}

.fade-in {
  -webkit-animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  background: rgba(0, 0, 0, 1);
}
.home_prev-heden-social-block {
  width: 250%;
  height: 100%;
  position: absolute;
  background: #000;
}
.home_prev-heden-social-block.fade-social {
  width: 0%;

  transition: 1.5s;
  transition-delay: 1s;
}
.home_desc-video-cont-hidden {
  background-color: #000;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.home_desc-video-cont-hidden-block {
  position: absolute;
  width: 100%;
  height: 700px;
  z-index: 999;
}
.home_desc-video-cont-show {
  width: 100%;
  height: 0;
}
.home_desc-video-cont-show.active {
  height: 100%;
  transition: 1.5s;
  transition-delay: 1s;
}
.home_desc-video-cont-hidden.active {
  height: 0;
  transition: 1.5s;
  transition-delay: 1s;
}
/* @keyframes right-animate {
  0% {
    width: 0%;
  }
  100% {
    width: 150%;
  }
} */
.home_prev-header {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 2.2vw;
  text-transform: uppercase;
  padding-top: 50px;
  font-weight: 700;
  margin-left: 5%;
}
.home_prev-desc {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 10px;
  font-size: 1.15vw;
  margin-left: 5%;
}
.home_comment-arrow-disabled.trasform {
  transform: rotate(180deg);
}
.home_comment-arrow-enabled.transform {
  transform: rotate(180deg);
  padding-right: 22px;
}
.home_desc-video-cont-hidden-block-rel {
  position: relative;
}
.home_prev-all-cont-mob {
  display: none;
}
.home_why-pix-right-text-cont {
  display: none;
}
.home_why-pix-right-sation-text {
  font-weight: 500;
  font-family: "Manrope";
  width: 100%;
  text-align: justify;
  font-size: 14px;
  margin-top: 12px;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-header {
  -webkit-animation: fade-in-header 1.8s ease-out 1s both;
  animation: fade-in-header 1.8s ease-out 1s both;
}

@-webkit-keyframes fade-in-header {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-header {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-img {
  -webkit-animation: fade-in-img 1.8s ease-out 1.2s both;
  animation: fade-in-img 1.8s ease-out 1.2s both;
}

@-webkit-keyframes fade-in-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.home_prev-all-cont {
  width: 90%;
  margin-left: 5%;
  margin-top: 40px;
  display: flex;
}
.home_prev-img-cont {
  width: 90%;
  height: inherit;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3%;

  background-color: #000;
}
.home_prev-img-play {
  position: absolute;
  z-index: 999999;
  width: 10%;
}
.home_prev-img {
  width: 100%;

  height: 100%;
  object-fit: cover;
  background-color: #000;
  border-radius: 35px;
}
.home_prev-social-block {
  background-color: #000;

  margin-top: 15px;
}
.home_prev-social-rectangle {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.85) 0%,
    rgba(208, 93, 222, 0.85) 100%
  );
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_prev-social-rectangle::before {
  content: "";
  display: block;
  padding-top: 95%; /* Равная высота блока (в примере 100%) */
}
.home_rectangle-soc-img.fb {
  width: 38%;
}
.home_prev-social-cont {
  display: flex;
  flex-direction: column;
  /* height: inherit; */
  justify-content: space-between;

  width: fit-content;
  position: relative;
  width: 8%;
  margin-top: -15px;
}
.home_prev-social-vertical-line {
  height: 90%;
  width: 2px;
  background-color: #fff;
  margin-left: 50%;
  position: absolute;
  z-index: -1;
  margin-top: 15px;
}
.home_prev-social-horizontal-line {
  height: 2px;
  width: 290px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  z-index: -1;
}
.home_h-text-mobile {
  display: none;
}
.home_desc-text-mobile {
  display: none;
}
.home-login-mobile-btn {
  display: none;
}
.home-btn-cont-mobile {
  display: none;
}
.home_sections-block-mobile {
  display: none;
}
/* .home_screen-imgs-cont {
  width: 74%;
} */
.home_screen-imgs-conts {
  width: 74%;
}
.home_desc-video-cont-hidden-block-rel-mob {
  display: none;
}
.wrapper {
  background-color: #fff;
}
.home_why-pix-right-text-more {
  display: none;
}
.home_promo-right-btn-cont-mob {
  display: none;
}
.home_gradient-text {
  width: 90%;
}
.home_comment-arrow-block {
  position: absolute;
  right: 0 !important;
  display: flex;
}
.home_comment-arrow-block-img.transform {
  transform: rotate(180deg);
  margin-right: 15px;
}
@media only screen and (min-width: 1500px) {
  .video_video-item-center {
    height: 88%;
    margin-top: -11px;
  }
}
@media only screen and (min-width: 1500px) {
  .video_video-item-center {
    height: 88%;
    margin-top: -11px;
  }
}
/* @media (min-width: 1400px) and (max-width: 1500px) {
  .home_prev-social-rectangle {
    width: 80px;
    height: 80px;
    border-radius: 20px;
  }
} */
/* @media (min-width: 1590px) and (max-width: 1700px) {
  .home_prev-social-rectangle {
    width: 90px;
    height: 90px;
    border-radius: 20px;
  }
} */
/* @media (min-width: 1790px) and (max-width: 1900px) {
  .home_prev-social-rectangle {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }
} */
.home_comment-arrow-block-img {
  width: 15px;
  height: 30px;

  background-image: url("../../assets/icons/disabledArrwo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.home_comment-arrow-block-img.disabled-tansform {
  background-image: url("../../assets/icons/enavledArrow.svg");
  transform: rotate(180deg);
}
.home_comment-arrow-block-img.disabled {
  background-image: url("../../assets/icons/enavledArrow.svg");
  margin-right: 15px;
}
@media only screen and (max-width: 1210px) {
  .video_video-item-center {
    margin-top: -26px;
    height: 92%;
  }
}
@media only screen and (max-width: 800px) {
  .wrapper {
    background-color: rgba(248, 248, 248, 0.945098);
  }
  .home_h-text {
    display: none;
  }
  .home_h-text-mobile {
    display: block;
    margin-left: 5%;
    padding-top: 100px;
    font-weight: 700;
    font-size: 6.9vw;
    color: "#000";
  }
  .home_desc-text-mobile {
    display: block;
    width: 80%;
    margin-left: 10%;
    font-weight: 400;
    color: "#000";
    /* font-size: 14px; */
    font-size: 4vw;
    margin-top: 35px;
  }
  .home-login-mobile-btn {
    display: block;
    width: 90%;
    margin-left: 5%;
    height: 41px;
    margin-top: 35px;
    background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 4.2vw;
    font-weight: 700;
  }
  .home-btn-cont-mobile {
    display: block;
    width: 90%;
    margin-left: 5%;
    margin-top: 25px;
  }
  .home-btn-mobile {
    display: block;
    font-size: 4.2vw;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 0.5px solid #040404;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #d05dde 0%, #8754d9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    height: 41px;
  }
  .home_sections-block-mobile {
    display: block;
    width: 90%;
    margin-left: 5%;
  }
  .home_sections-header {
    margin-top: 55px;
    color: #000000;
    font-weight: 700;
    font-size: 4.2vw;
  }
  .home_section-cont {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .home_section-cont-link {
    border: 0.5px solid #040404;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 47%;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 4.2vw;
    font-weight: 400;
    color: #000000;
    margin-top: 15px;
  }
  .video_video-block {
    display: none;
  }
  .home_more-cont {
    display: none;
  }
  .home_partners-cont {
    margin-left: 5%;
    width: 90%;
    margin-top: 70px;
  }
  .home_partners-text {
    font-size: 4.2vw;
  }
  .home_partners-block {
    margin-top: 10px;
  }
  .home_screen-block {
    margin-top: 70px;
    width: 90%;
    margin-left: 5%;
    position: relative;
    /* height: 1400px; */
  }
  .home_gradient-cont {
    position: absolute;
    height: 111px;
    font-size: 3.8vw;
  }
  .home_screen-imgs-cont {
    /* position: absolute; */
    top: 111px;
    flex-direction: column;

    margin-top: 121px;
  }
  .home_screen-img-cont {
    width: 100%;
    position: relative;
    margin-top: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
  }
  .home_screen-text {
    position: absolute;
    width: 100%;
    height: 66px;
    background-color: #fff;
    left: 0;
    z-index: 99999;
    bottom: 0;
    font-size: 4vw;
    text-align: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .home_screen-cont {
    flex-direction: column;
    width: 100%;
  }
  .home_screen-img {
    width: 100% !important;
  }
  .home_screen-imgs-conts {
    width: 100%;
  }
  .home_screen-right-screen {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    position: relative;
    margin-left: 0;
    margin-top: 15px;
    height: 290px;
  }
  .home_screen-img-right {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .home_prev-block {
    margin-top: 70px;
    border-radius: 25px;
    position: relative;
  }
  .home_prev-header {
    width: 61%;
    font-size: 4.2vw;
    padding-top: 34px;
    margin-left: 10%;
  }
  .home_prev-desc {
    width: 83%;
    font-size: 3.6vw;
    margin-top: 15px;
    margin-left: 10%;
  }
  .home_prev-all-cont {
    display: none;
  }
  .home_prev-all-social-cont {
    width: 80%;
    margin-left: 10%;
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .home_prev-all-social-black-mob {
    width: 13%;
    background: #000;
    border-radius: 20px;
    position: relative;
  }
  .home_prev-all-social-block-mob {
    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(130, 83, 216, 0.85) 0%,
      rgba(208, 93, 222, 0.85) 100%
    );
    border-radius: 20px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home_prev-all-social-black-mob::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .home_horizontalline-mob {
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
  }
  .home_prev-img-cont {
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
  }
  .home_vertical-line-mob {
    position: absolute;
    width: 2px;
    height: 510px;
    background-color: rgba(248, 248, 248, 0.945098);
    margin-left: 50%;
  }
  .home_desc-video-cont-hidden-block-rel {
    display: none;
  }
  .home_desc-video-cont-hidden-block-rel-mob {
    display: block;
    width: 90%;
    margin-left: 5%;
    margin-top: 7px;
  }
  .home_desc-video-blocks-item {
    width: 100%;

    background: linear-gradient(
      180deg,
      rgba(130, 83, 216, 0.75) 0%,
      rgba(208, 93, 222, 0.75) 100%
    );
    backdrop-filter: blur(7.5px);

    border-radius: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.8vw;
    font-weight: 700;
    color: #fff;
    padding-top: 1px;
    padding-bottom: 1px;
    flex-direction: column;
  }
  .home_desc-video-blocks-item-header {
    width: 50%;
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home_desc-video-blocks-item-header-desc {
    padding-bottom: 10px;
    width: 90%;
    text-align: justify;
    font-weight: 400;
    font-size: 3.6vw;
  }
  .home_desc-video-blocks-fake-block {
    width: 100%;
    height: 34px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    border-radius: 25px;
    z-index: 99;
  }
  .home_prev-all-cont-mob {
    display: block;
  }
  .home_why-pix-cont {
    margin-top: 0px;
    width: 90%;
    margin-left: 5%;
  }
  .home_why-pix-header {
    font-size: 4.2vw;
    margin-top: 70px;
  }
  .home_why-pix-desc {
    font-size: 4.2vw;
    margin-top: 15px;
  }
  .home_why-pix-content-cont {
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
  }
  .home_why-pix-right-content {
    width: 100%;
  }
  .home_why-pix-left-cont {
    margin-top: 80px;
  }
  .home_why-pix-right-text-cont {
    /* background-color: #fff; */
    height: 105px;

    background: #fff;
    position: relative;
    margin-top: -40px;
    transition: 0.5s;
    display: block;
  }
  .home_why-pix-right-text-cont.active {
    height: 200px;
    transition: 0.5s;
  }
  .home_why-pix-right-text {
    font-size: 3.6vw;
    width: 90%;
    margin-left: 5%;
    text-align: justify;
    margin-top: 10px;
    height: 37px;
    overflow: hidden;
    padding-top: 20px;
    transition: 0.5s;
  }
  .home_why-pix-right-text.active {
    overflow: auto;
    height: auto;
    transition: 0.5s;
  }
  .home_why-pix-right-text-more {
    display: block;
    margin-left: 5%;
    background: linear-gradient(
      180deg,
      rgba(130, 83, 216, 0.75) 0%,
      rgba(166, 88, 219, 0.75) 46.03%,
      rgba(208, 93, 222, 0.75) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 4vw;
    margin-top: 17px;
    cursor: pointer;
  }
  .home_why-pix-left-cont {
    width: 90%;
    margin-left: 5%;
    margin-left: 0px;
    margin-top: 45px;
  }
  .home_why-pix-left-cont-header {
    margin-left: 0px;
    font-size: 4.2vw;
    text-align: center;
    margin-left: 10%;
  }
  .btn_cont {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    margin-left: 10%;
  }
  .home_why-pix-left-cont-desc {
    margin-top: 15px;
    font-size: 3.8vw;
    text-align: center;
    margin-left: 10%;
  }
  .home_why-pix-left-text {
    margin-left: 10px;
  }
  .home_why-pix-left-text {
    font-size: 3.8vw;
  }
  .home_why-pix-left-item-num-cont {
    width: 23px;
    height: 23px;
    font-size: 3.5vw;
  }
  .home_why-pix-left-item-text {
    font-size: 2.4vw;
    width: 95%;
  }
  .home_why-pix-left-item-cont {
    margin-left: 10%;
    width: 90%;
  }
  .home_budjet-block {
    width: 90%;
    margin-left: 5%;
    margin-top: 75px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home_budjet-header {
    font-size: 4.2vw;
    font-weight: 700;
  }
  .home_budjet-desc {
    margin-top: 10px;
    font-weight: 700;
    font-size: 3.8vw;
    width: 95%;
    margin-left: 2.5%;
  }
  .home_budjet-purple-block-text {
    flex-direction: column;
    position: relative;
    margin-top: 15px;
  }
  .home_budjet-purple-left-text {
    margin-right: 0px;
    font-size: 3.8vw;
    margin-top: 20px;
  }
  .home_budjet-purple-right-text {
    margin-left: 0px;
    font-size: 3.8vw;
    margin-top: 15px;
  }
  .home_budjet-purple-line {
    transform: rotate(90deg);
    position: absolute;
    top: -5px;
  }
  .home_budjet-data-cont {
    flex-direction: column;
  }
  .home_budjet-data-line {
    display: none;
  }
  .home_budjet-img-cont {
    width: 100%;
  }
  .home_budjet-img {
    border-radius: 25px;
  }
  .home_budjet-text-cont {
    width: 100%;
    border-radius: 25px;
    height: 335px;
    margin-top: 5px;
    padding-bottom: 10px;
  }
  .home_budjet-text {
    font-size: 3.8vw;
  }
  .home_budjet-btn {
    font-size: 4.2vw;
  }
  .home_budjet-btn-cont {
    margin-top: 15px;
  }
  .home_promo-block {
    margin-top: 75px;
    flex-direction: column-reverse;
    width: 90%;
    margin-left: 5%;
  }
  .home_promo-right-block {
    margin-left: 0px;
  }
  .home_promo-right-header {
    font-size: 4.2vw;
  }
  .home_promo-right-desc {
    font-size: 3.8vw;
  }
  .home_promo-right-item-cont {
    margin-top: 0px;
  }
  .home_promo-right-item-block {
    flex-direction: column;
    margin-top: 0px;
    align-items: center;
    margin-left: 10%;
  }
  .home_promo-right-btn-cont {
    display: none;
  }
  .home_promo-right-item {
    margin-top: 35px;
  }
  .home_promo-right-item-text {
    font-size: 3.8vw;
  }
  .home_promo-left-block {
    display: flex;
    margin-top: 80px;
    overflow-x: scroll;
    overflow-y: scroll;
    width: 110%;
    /* margin-left: -50%; */
  }
  .home_promo-left-block::-webkit-scrollbar {
    width: 0;
  }
  .home_promo-left-promo-video {
    width: 235px;
    border-radius: 35px;
  }
  .home_promo-left-promo-video.l {
    /* margin-left: -52%; */
  }
  .home_promo-right-btn-cont-mob {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
  .home_comment-cont {
    width: 90%;
    margin-left: 5%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 25px 100px 25px 25px;
    margin-top: 75px;
    height: 550px;
  }
  .home_comment-header {
    font-size: 4.2vw;
    margin-left: 5%;
    width: 80%;
    padding-top: 22px;
  }
  .home_comment-text {
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
    font-size: 3.6vw;
  }
  .home_comment-bottom-cont {
    width: 90%;
    margin-left: 5%;
  }
  .home_comment-user-desc {
    font-size: 2.6vw;
    width: 180px;
  }
  .home_comment-arrow-block {
    display: flex;
    align-items: center;
  }
  .home_comment-avatar {
    width: 40px;
    height: 40px;
  }
  .popup-video-item {
    width: 100%;
    height: 200px;
  }
  .close_popup {
    width: 20px;
    height: 20px;
    right: 20px;
    top: 120px;
  }
  .home_why-pix-right-sation-text {
    display: none;
  }
  .home_comment-arrow-block-img {
    width: 10px;
    height: 20px;
  }
}
