.footer_cont {
  background-color: #000000;
  margin-top: 165px;
  position: relative;
}
.footer_header-cont {
  padding-top: 79.5px;
  margin-left: 5%;
  display: flex;
  align-items: center;
  width: 90%;
}
.footet_header-input-header {
  font-size: 29.25px;
  font-weight: 700;
  color: #ffffff;
  font-family: "Manrope";
}
.footet_header-input {
  width: 300px;
  height: 55.5px;
  border: 1px solid #ffffff;
  border-radius: 50px;
  background-color: #000000;
  padding-left: 83.25px;
  outline: none;
  color: #fff;
  font-size: 16px;
}
.footet_header-input-btn {
  width: 145.5px;
  height: 60px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  font-family: "Manrope";
}
.footet_header-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 400;
  font-family: "Manrope";
}
.footet_header-input-block {
  margin-top: 45px;
  position: relative;
  width: 390px;
}
.footer_header-desc {
  color: #fff;
  width: 240.75px;
  font-size: 1vw;
  font-weight: 500;
  margin-left: 19%;
  line-height: 24px;
  font-family: "Manrope";
}
.footer_header-line {
  height: 38.25px;
  border: 1px solid #ffffff;
  margin-left: 1.5%;
}
.footer_header-link-cont {
  margin-left: 1.5%;
  height: 24px;
  margin-top: -30px;
}
.footer_header-link {
  color: #fff;
  font-size: 1.1vw;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
  font-family: "Manrope";
}
.footer_middle-cont {
  margin-left: 77.25px;
  margin-top: 50px;
  display: flex;
  margin-left: 5%;
  width: 90%;
}
.footer_middle-logo {
  width: 387px;
}
.footer_middle-link {
  color: #fff;
  font-size: 1.1vw;
  font-weight: 400;
  margin-top: 5px;
  cursor: pointer;
  font-family: "Manrope";
}
.footer_middle-text {
  color: #fff;

  font-size: 1.1vw;
  font-weight: 400;
  margin-top: 5px;
  cursor: pointer;
  font-family: "Manrope";
}
.footer_middle-links-cont {
  display: flex;
  margin-left: 19%;
  margin-top: 80px;
  width: 80%;
  justify-content: space-between;
}
.footer_middle-links-blok {
}
.footer_social-media-cont {
  width: 100%;
  height: 76.5px;
  border-top: 2px solid rgba(217, 217, 217, 0.5);
}
.footer_social-burn {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin-left: 77.25px;
  font-family: "Manrope";
}
.footer_social-media-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_social-cont {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  margin-right: 77.25px !important;
}
.footer_social-img {
  width: 25px;
  cursor: pointer;
}
.footet_header-input-btn:hover {
  background: grey;
  border-radius: 50px;
  color: #fff;
}
.footer_middle-link:hover {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}
.footer_header-link:hover {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}
.footer_social-media-bar-mob {
  display: none;
}
.footer_middle-text.mob {
  margin-left: 10px;
  margin-top: 120px !important;
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media only screen and (max-width: 800px) {
  .footer_header-cont {
    flex-direction: column;
  }
  .footet_header-input-cont {
    width: 100%;
  }
  .footet_header-input-header {
    font-size: 4.2vw;
  }
  .footet_header-input-block {
    width: 100%;
    margin-top: 20px;
  }
  .footet_header-input {
    width: 70%;
    height: 41px;
    padding-left: 35px;
  }
  .footet_header-input-btn {
    height: 100%;
    right: 20px;
  }
  .footer_header-line {
    display: none;
  }
  .footer_header-desc {
    margin-left: 0;
    width: 100%;
    text-align: center;
    font-size: 3vw;
    margin-top: 15px;
  }
  .footer_header-link-cont {
    margin-top: 0;
  }
  .footer_header-link {
    font-size: 3.2vw;
    text-align: center;
  }
  .footer_middle-cont {
    flex-direction: column;
  }
  .footer_middle-logo {
    width: 30%;
    display: none;
  }
  .footer_middle-links-cont {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    align-items: center;
    margin-top: -20px;
  }
  .footer_middle-link {
    text-align: center;
    font-size: 3.2vw;
  }
  .footer_middle-text {
    text-align: center;
    font-size: 3.2vw;
  }
  .footer_social-media-cont {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
  .footer_social-burn {
    margin-left: 0%;

    font-size: 3vw;
  }
  .footer_social-cont {
    margin-right: 5% !important;
    width: 50%;
    display: none;
  }
  .footer_social-media-bar-mob {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
  .footer_social-media-bar-cont-mob {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  .footer_social-img {
    width: 12%;
  }
  .home_comment-user-desc-cont {
    width: fit-content;
  }
  .home_comment-arrow-block {
    position: absolute;
    right: 10px;
  }
  .footer_header-desc {
    display: none;
  }
  .footer_header-line {
    display: none;
  }
  .footer_header-link-cont {
    display: none;
  }
  .footer_middle-links-blok.mob {
    display: none;
  }
}
