.job_header {
  font-weight: 700;
  font-size: 48px;
  padding-top: 130px;
  margin-left: 10%;
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.job_desc {
  font-size: 36px;
  font-weight: 700;
  margin-left: 10%;
  margin-top: 35px;
}
.job_contact-header {
  font-size: 55px;
  text-align: center;
  margin-top: 90px;
  font-weight: 300;
}
.job_text {
  width: 80%;
  margin-left: 10%;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope";
}

.job_img {
  width: 80%;
  margin-left: 10%;
  margin-top: 75.75px;
}

.job_input {
  outline: none;
  width: 400px;
  height: 40px;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #000;
  font-size: 16px;
}
.job_inputs-cont {
  display: flex;
  width: 60%;
  margin-left: 20%;
  justify-content: space-between;
  margin-top: 67.5px;
}
.jobe_resume-cont {
  width: 60%;
  margin-left: 20%;
  margin-top: 10px;
  position: relative;
}
.jobe_resume-input {
  width: 100%;
  height: 40px;
  border: 1px solid #000;
  border-radius: 10px;
}
.jobe_resume-header {
  margin-top: 30px;
  margin-left: 20%;
}
.job_resume-btn {
  width: 150px;
  height: 43px;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: -5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.job_vacancies-cont-block {
  width: 80%;
  margin-left: 10%;
  margin-top: 68.25;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  display: flex;
  margin-top: 61.5px;
  /* border: 1px solid grey; */
}
.job_vacancies-left-block {
  width: 60%;
}
.job_vacancies-left-item {
  width: 94%;
  margin-left: 3%;
  margin-top: 12.75px;
  font-weight: 400;
  font-size: 18px;
  font-family: "Manrope";
}
.job_vacancies-left-header {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
  margin-left: 3%;
  margin-top: 31.5px;
}
.job_vacancies-header {
  margin-left: 10%;
  font-size: 36px;
  font-weight: 700;
  margin-top: 161.25px;
}
.job_vacancies-block {
  margin-top: 69px;
}
.job_vacancies-right-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    191.1deg,
    rgba(173, 129, 255, 0.65) 8.2%,
    rgba(208, 93, 222, 0.65) 91.8%
  );
  border-radius: 0px 35px 35px 0px;
  width: 40%;
}
.job_vacancies-right-header {
  font-size: 24px;
  font-family: "Manrope";
  font-weight: 400;
  margin-top: 27px;
}
.job_vacancies-right-item-dot {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background-color: #000;
  position: absolute;
  top: 20px;
  left: -15px;
}
.job_vacancies-right-item-block {
  display: flex;
  width: 70%;
  margin-left: 15%;
  position: relative;
}
.job_vacancies-right-item {
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope";
  margin-left: 10px;

  margin-top: 10px;
}
.job_vacancies-right-items {
  margin-top: 15.75px;
}
.job_vacancies-right-btn {
  width: 231px;
  height: 39.75px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Manrope";
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 27px;
  cursor: pointer;
}
.job_vacancies-right-btn:hover {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  transition: 0.5s;
}
.job_inputs-header {
  font-size: 36px;
  font-weight: 700;
  margin-left: 10%;
  margin-top: 159px;
}
.job_inputs-desc {
  font-size: 24px;
  font-weight: 400;
  margin-top: 5px;
  margin-left: 10%;
}
@media only screen and (max-width: 600px) {
  .job_header {
    font-size: 4.2vw;
    margin-left: 5%;
  }
  .job_desc {
    font-size: 4.2vw;
    margin-left: 5vw;
    margin-top: 20px;
  }
  .job_text {
    margin-left: 5%;
    font-size: 3.8vw;
    width: 90%;
    text-align: justify;
  }
  .job_img {
    width: 90%;
    margin-left: 5%;
  }
  .job_vacancies-header {
    margin-left: 5%;
    font-size: 4.2vw;
    margin-top: 75px;
  }
  .job_vacancies-cont-block {
    width: 90%;
    margin-left: 5%;
    flex-direction: column;
  }
  .job_vacancies-left-header {
    font-size: 4.2vw;
    margin-left: 5%;
  }
  .job_vacancies-left-item {
    width: 90%;
    margin-left: 5%;
    font-size: 3.8vw;
    text-align: justify;
  }
  .job_vacancies-left-block {
    width: 100%;
  }
  .job_vacancies-right-block {
    width: 100%;
    border-radius: 25px;
    margin-top: 15px;
  }
  .job_vacancies-right-header {
    font-size: 4.2vw;
  }
  .job_vacancies-right-item {
    font-size: 3.8vw;
  }
  .job_inputs-header {
    margin-left: 5%;
    font-size: 4.2vw;
    margin-top: 75px;
  }
  .job_inputs-desc {
    margin-left: 5%;
    font-size: 3.8vw;
  }
  .job_inputs-cont {
    flex-direction: column;
    margin-left: 5%;
    width: 90%;
    margin-top: 15px;
  }
  .job_input {
    width: 100%;
  }
  .job_input-cont {
    margin-top: 10px;
  }
  .jobe_resume-cont {
    width: 90%;
    margin-left: 5%;
  }
  .jobe_resume-header {
    margin-left: 5%;
  }
  .job_resume-btn {
    height: 100%;
  }
  .jobe_resume-input {
    width: 100%;
  }
}
