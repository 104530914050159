.contact_heater-text {
  padding-top: 130px;
  font-size: 36px;
  margin-left: 10%;
  font-weight: 700;
}
.contact_heater-text-purple {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.contact_data1-cont {
  width: 64%;

  position: relative;
  height: fit-content;
}
.contact_img1 {
  width: 100%;
  margin-left: -8%;
}
.contact_img1.block2 {
  margin-left: 5%;
  width: 95%;
  margin-top: -18vh;
}
.contact_data1-block {
  width: 80%;
  margin-left: 10%;

  margin-top: 40px;
  display: flex;
}
.contact_rectangle {
  width: 76%;
  height: 390px;
  background: linear-gradient(
    226.05deg,
    rgba(130, 83, 216, 0.65) 8.17%,
    rgba(208, 93, 222, 0.65) 98.15%
  );
  border-radius: 35px;
  margin-left: 12%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.contact_blue {
  width: 350px;
  height: 460px;
  background-color: rgb(154, 204, 231);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.contact_content-block {
  margin-top: 30px;
}

.contact_img {
  height: 360px;
  position: absolute;
  top: 50px;
  border-radius: 15px;
  left: 200px;
}

.contact_leftblock {
  position: relative;
}

.contact_content-block {
  display: flex;
}

.contact_rightBlock {
  width: 40%;
  margin-left: 30%;
  margin-top: 20px;
}

.contact_rightBlock-header {
  font-size: 30px;
}

.contact_rightBlock-desc {
  width: 80%;
  font-size: 17px;
  margin-top: 20px;
  line-height: 30px;
}

.contact_heater-text-desc {
  font-size: 28px;
  font-weight: 400;

  margin-top: 20px;
  margin-left: 10%;
}

.job_text-area {
  width: 650px;
  height: 40px;
  border: 1px solid #000;
  background-color: #fffcf8;
  border-radius: 10px;
  margin-top: 10px;
}

.jobs_text-area-cont {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.contact_btn {
  width: 250px;
  height: 40px;
  border-radius: 10px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.contact_btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.contact_data1-text-cont {
  width: 36%;
  height: 60vh;
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  margin-top: 8vh;
  display: flex;

  flex-direction: column;
  align-items: center;
}
.contact_data1-text-header {
  font-size: 36px;
  margin-top: 18px;
  font-family: "Manrope";
  font-weight: 400;
}
.contact_data1-text {
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope";
  width: 80%;
  margin-top: 60px;
}
.contact_heater-text-two {
  font-size: 32px;
  font-weight: 700;
  margin-left: 10%;
  margin-top: 20px;
}
@media only screen and (max-width: 600px) {
  .contact_heater-text {
    margin-left: 5%;
    font-size: 4.2vw;
  }
  .contact_data1-block {
    flex-direction: column;
    width: 90%;
  }
  .contact_data1-text-cont {
    margin-top: 10px;
  }
  .contact_img-cont {
    width: 100%;
  }
  .contact_img1 {
    width: 100%;
    margin-left: -2%;
  }
  .contact_data1-cont {
    width: 100%;
  }
  .contact_data1-text-cont {
    width: 90%;
    height: auto;
    padding-bottom: 15px;
  }
  .contact_data1-text-header {
    font-size: 3.8vw;
  }
  .contact_data1-text {
    margin-top: 15px;
    font-size: 3.6vw;
  }
  .contact_img1.block2 {
    margin-left: -2.5%;
    margin-top: -15px;
  }
  .contact_heater-text-desc {
    font-size: 3.6vw;
    margin-left: 5%;
  }

  .contact_heater-text-two {
    font-size: 4.2vw;
    font-weight: 700;
    margin-left: 5%;
    margin-top: 20px;
  }
}
