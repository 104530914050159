.features_header {
  font-size: 36px;
  color: #000;
  padding-top: 130px;
  margin-left: 10%;
  font-weight: 700;
}
.features_description {
  width: 50%;
  margin-left: 25%;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  margin-left: 10%;
  width: 80%;
  font-weight: 400;
}
.features_data-item-cont {
  width: 33%;
  margin-top: 100px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 3px -2px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  position: relative;
  padding-top: 20px;
  height: 280px;
  padding-bottom: 20px;
}
.features_data-item-cont.active {
  height: auto;
  transition: 0.5s;
}
.features_data-cont {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}
.features_img {
  width: 130px;
  position: absolute;
  top: -60px;
}
.features_data-header {
  font-size: 27px;
  width: 70%;
  text-align: center;
  height: 120px;
  font-weight: 400;
  margin-top: 75px;
  font-family: "Manrope";
}
.features_data-desc {
  width: 90%;

  text-align: center;
  font-size: 17px;
  line-height: 30px;
  font-family: "Manrope";
}
.features_video-header {
  text-align: center;
  margin-top: 150px;
  font-size: 65px;
  font-family: "Manrope";
}
.features_video {
  width: 50%;
  margin-left: 25%;
  margin-top: 40px;
}
.features_data-item-header {
  font-size: 1.87vw;
  font-weight: 400;
  width: 84%;
  text-align: center;
  margin-top: 50px;
  font-family: "Manrope";
  margin-left: 8%;
}
.features_data-item-desc {
  width: 90%;
  margin-left: 5%;
  margin-top: 19.5px;
  font-size: 1.1vw;
  font-weight: 400;
  font-family: "Manrope";
  height: 109px;
  overflow: hidden;
  mix-blend-mode: normal;
  text-align: justify;
}
.features_data-item-desc.active {
  height: fit-content;
  transition: 0.5;
}
.features_data-item-more {
  margin-left: 0;
}
.features_data-item-more {
  margin-left: 5%;
  margin-top: 10px;
  background: linear-gradient(
    180deg,
    rgba(130, 83, 216, 0.75) 0%,
    rgba(208, 93, 222, 0.75) 72.41%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.features_data-item-more:hover {
  background: linear-gradient(180deg, #8253d8 0%, #d05dde 72.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
}
.features_gradient-block {
  width: 90%;
  height: 109px;

  margin-left: 5%;
  position: absolute;
  top: 169px;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    331deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 250, 250, 0) 37%
  );
}
@media only screen and (max-width: 800px) {
  .features_header {
    margin-left: 5%;
    font-size: 4.2vw;
  }
  .features_description {
    margin-left: 5%;
    font-size: 3.8vw;
    width: 90%;
    line-height: 1.3;
    text-align: justify;
  }
  .features_data-cont {
    width: 90%;
    margin-left: 5%;
    margin-top: 60px;
  }
  .features_data-item-cont {
    width: 100%;
  }
  .features_data-item-header {
    font-size: 3.8vw;
  }
  .features_data-item-desc {
    font-size: 3.6vw;
  }
  .features_gradient-block {
    margin-top: -45px;
  }
}
