.popup-contacts_cont {
  width: 600px;
  height: 580px;
  background-color: #fff;
  position: fixed;

  box-shadow: 3px -2px 15px 3px rgba(0, 0, 0, 0.25);
  z-index: 999999;
  bottom: 0;
  right: 0;
  border-top-left-radius: 35px;
}
.popup-contacts_input {
  width: 230px;
  border: 2px solid #040404;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 40px;
  outline: none;
  margin-top: 5px;
  font-size: 16px;
}
.popup-contacts_inputs-cont {
  display: flex;
  width: 500px;
  margin-left: 50px;
  justify-content: space-between;
  margin-top: 20px;
}
.popup-contacts_btn {
  width: 206px;
  height: 49px;

  background: #000000;
  border: 2px solid #040404;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  /* or 36px */

  text-align: justify;

  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-contacts_btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  cursor: pointer;
}
.popup-contacts_header-close-img {
  width: 30px;
  cursor: pointer;
}
.popup-contacts_header-cont {
  display: flex;
  margin-left: 50px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
  justify-content: space-between;
  width: 500px;
  align-items: center;
}
.popup-contacts_input-cont.message {
  margin-left: 50px;
  margin-top: 10px;
}
.popup-contacts_input.message {
  width: 495px;
  height: 60px;
}
.popup-contacts_input-req-img {
  width: 15px;
  padding-right: 5px;
  height: 15px;
}
.popup-contacts_input-cont-req {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 800px) {
  .popup-contacts_cont {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .popup-contacts_inputs-cont {
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }
  .popup-contacts_inputs-cont {
    margin-left: 0px;
  }
  .popup-contacts_input {
    width: 60%;
    margin-left: 20%;
    height: 30px;
  }
  .popup-contacts_input-header {
    font-size: 3vw;
  }
  .popup-contacts_input-cont.message {
    margin-left: 0%;
    margin-top: 5px;
    width: 100%;
  }
  .popup-contacts_input.message {
    width: 60%;
    margin-left: 20%;
    height: 40px;
  }
  .popup-contacts_btn-cont {
    margin-top: 10px;
  }
  .popup-contacts_btn {
    height: 35px;
    font-size: 3vw;
  }
  .popup-contacts_header-close {
    position: absolute;
    right: 20px;
  }
  .popup-contacts_input-cont-req {
    margin-left: 20%;
  }
}
